<template>
  <div
    class="searchResult"
    v-watermark
  >
    <commonNavBar :title="'联网单位查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay
      :show="loadingShow"
      class-name="myLoading"
    >
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="searchBox">
      <form action="/">
        <van-search
          :value="$route.query.text"
          show-action
          left-icon=""
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                :value="+$route.query.type"
                :options="option1"
                disabled
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <div class="siftDp">
        <van-dropdown-menu
          active-color="#3ea7f0"
          :close-on-click-outside="flag"
        >
          <van-dropdown-item
            :title="addTitle"
            ref="item"
            @open="addOpened"
            @opened="addOpened"
            :disabled="loadingShow"
          >
            <van-tree-select
              :items="items"
              :active-id.sync="activeIds"
              :main-active-index.sync="activeIndex"
              @click-item="itemClickHandler"
              @click-nav="navClick"
            />
            <div class="myButton">
              <van-button
                @click="(activeIds = []), (activeIndex = items.length)"
                color="#969799"
                block
                size="small"
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="status"
            :options="statusOption"
            :title="statusTitle"
            @change="statusChange"
            :disabled="loadingShow"
            @open="statusOpened"
          />
          <van-dropdown-item
            :title="timeTitle"
            ref="timeItem"
            @open="openTimeOpened"
            :disabled="loadingShow"
          >
            <van-cell
              title="选择开通日期区间"
              :value="selectTime"
              @click="showTimeSelect"
              is-link
              center
              title-style="flex:none"
            />
            <van-field name="radio">
              <template #input>
                <van-radio-group
                  v-model="radio"
                  direction="horizontal"
                  @change="openTimeChange"
                >
                  <van-radio
                    :name="item.value"
                    v-for="(item, index) in radioArr"
                    :key="index"
                  >
                    <template #default>
                      <van-tag
                        :color="radio === item.value ? '#3e89fa' : '#969799'"
                        size="medium"
                      >{{
                        item.text
                      }}</van-tag>
                    </template>
                    <template #icon>
                      <div></div>
                    </template>
                  </van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <div class="myButton">
              <van-button
                @click="(selectTime = ''), (radio = '')"
                color="#969799"
                block
                size="small"
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmOpenTimeClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            :title="moreTitle"
            ref="moreItem"
            :disabled="loadingShow"
            @open="openMoreOpened"
          >
            <div class="more">
              <div class="moreItem">
                <div class="moreLabel">单位性质</div>
                <div class="itemValue">
                  <van-field name="companyPropertySelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="companyPropertySelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in companyProperty"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="companyPropertySelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">单位规模</div>
                <div class="itemValue">
                  <van-field name="companyScaleSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="companyScaleSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in companyScale"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="companyScaleSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">物联情况</div>
                <div class="itemValue">
                  <van-field name="companyInternetSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="companyInternetSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in companyInternet"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="companyInternetSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">联网单位</div>
                <div class="itemValue">
                  <van-field name="isInternet">
                    <template #input>
                      <van-radio-group
                        v-model="isInternet"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="isInternet === 0 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >是</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="isInternet === 1 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >否</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">重点单位</div>
                <div class="itemValue">
                  <van-field name="emphasis">
                    <template #input>
                      <van-radio-group
                        v-model="emphasis"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="emphasis === 0 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >是</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="emphasis === 1 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >否</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">高危单位</div>
                <div class="itemValue">
                  <van-field name="highRisk">
                    <template #input>
                      <van-radio-group
                        v-model="highRisk"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="highRisk === 0 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >是</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="highRisk === 1 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >否</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">付费情况</div>
                <div class="itemValue">
                  <van-field name="payment">
                    <template #input>
                      <van-radio-group
                        v-model="payment"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="payment === 0 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >已付费</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="payment === 1 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >未付费</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">图纸资料</div>
                <div class="itemValue">
                  <van-field name="drawingsInformationSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="drawingsInformationSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in drawingsInformation"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="drawingsInformationSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
            <div class="myButton">
              <van-button
                color="#969799"
                block
                size="small"
                @click="
                  companyPropertySelect = [];
                companyScaleSelect = [];
                companyInternetSelect = [];
                isInternet = '';
                emphasis = '';
                highRisk = '';
                payment = '';
                drawingsInformationSelect = [];
                "
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmMoreClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="sequence"
            :options="sequenceOption"
            title-class="noIcon"
            :disabled="loadingShow"
            @change="sequenceChange"
            @open="sequenceOpened"
          >
            <template #title>
              <van-icon
                name="sort"
                :class="{
                  mySequence: sequence !== '' && sequence !== 'default',
                }"
              />
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <div
        class="searchBar"
        v-if="companyList.length"
      >
        <span class="label">搜索结果:共{{ total }}条</span>
      </div>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="companyList.length"
        @scroll="onScroll"
        ref="listBox"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="error"
          error-text="请求失败,点击重新加载!"
        >
          <!-- 卡片 -->
          <div
            class="myCard"
            v-for="(item, index) in companyList"
            :key="index"
            @click="$router.push(`/workTable/companyAddressInfo/${item.id}`)"
          >
            <div class="name">
              <span class="label">{{ item.name || "--" }}</span>
              <van-tag
                type="danger"
                v-if="item.status === 1"
              >停运</van-tag>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >在营</van-tag>
            </div>
            <p>单位编号:{{ item.code || "--" }}</p>
            <p>所属区县:{{ item.district || "--" }}</p>
            <p>开通日期:{{ item.openDatetime | openYmdFormat }}</p>
          </div>
        </van-list>
      </div>
      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
    <!-- 开通日期区间选择 -->
    <van-calendar
      title="开通日期区间选择"
      v-model="timeSelectShow"
      type="range"
      ref="timeSelectPicker"
      allow-same-day
      @confirm="onConfirmTime"
      :min-date="minDate"
      :max-date="maxDate"
      color="#3ea7f0"
      @closed="flag = true"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getNetworkCompanyList } from '@/api/networkCompany'
import { getDictionaryByTypeList, getDistrictList } from '@/api/system'

export default {
  name: 'searchResult',
  data() {
    return {

      option1: [
        { text: '单位名', value: 0 },
        { text: '编号', value: 1 }
      ],
      flag: true,
      companyList: [],
      loading: false,
      finished: false,
      loadingShow: false,
      top: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      error: false,
      items: [],
      activeIds: [],
      activeIndex: null,
      confirmActiveIds: [],
      confirmActiveIndex: null,
      addTitle: '区域',
      status: '',
      statusOption: [
        { text: '全部', value: 'all' },
        { text: '在营', value: 0 },
        { text: '停运', value: 1 }
      ],
      statusTitle: '状态',
      selectTime: '',
      confirmSelectTime: '',
      timeTitle: '开通日期',
      timeSelectShow: false,
      radio: '',
      confirmRadio: '',
      radioArr: [
        {
          value: '3-day',
          text: '近三天'
        },
        {
          value: '1-week',
          text: '近一周'
        },
        {
          value: '1-month',
          text: '近一个月'
        },
        {
          value: '3-month',
          text: '近三个月'
        },
        {
          value: '6-month',
          text: '近半年'
        },
        {
          value: '1-year',
          text: '近一年'
        }
      ],
      sequenceOption: [
        { text: '默认排序', value: 'default' },
        { text: '开通日期由近及远', value: 'desc' },
        { text: '开通日期由远及近', value: 'asc' }
      ],
      sequence: '',
      companyProperty: [],
      companyScale: [],
      companyInternet: [],
      drawingsInformation: [],
      companyPropertySelect: [],
      companyScaleSelect: [],
      companyInternetSelect: [],
      drawingsInformationSelect: [],
      isInternet: '',
      emphasis: '',
      highRisk: '',
      payment: '',
      moreTitle: '更多',
      confirmCompanyPropertySelect: [],
      confirmCompanyScaleSelect: [],
      confirmCompanyInternetSelect: [],
      confirmDrawingsInformationSelect: [],
      confirmIsInternet: '',
      confirmEmphasis: '',
      confirmHighRisk: '',
      confirmPayment: ''
    }
  },
  // 激活页面
  activated() {
    if (this.$refs.listBox) {
      this.$refs.listBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/companyAddressInfo')) {
      next(vm => {
        if (!vm.companyList.length) {
          vm.getDictionaryByTypeList()
          vm.getDistrictList()
          vm.onLoad()
        }
      })
    } else {
      next(vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.getDictionaryByTypeList()
        vm.getDistrictList()
        vm.onLoad()
      })
    }
  },
  computed: {
    minDate() {
      return new Date(1970, 0, 1)
    },
    maxDate() {
      return new Date()
    }
  },
  watch: {
    status: {
      handler: function (val, oldVal) {
        if (val === 'all') {
          this.statusTitle = '状态'
        } else if (val === 0) {
          this.statusTitle = '在营'
        } else if (val === 1) {
          this.statusTitle = '停业'
        } else {
          this.statusTitle = '状态'
        }
      }
    },
    confirmActiveIds: {
      handler: function (val, oldVal) {
        if (val.length === 1 && val.includes('0')) {
          this.addTitle = this.items[this.confirmActiveIndex].text
        } else if (val.length > 1) {
          this.addTitle = '区域(多选)'
        } else if (!val.length) {
          this.addTitle = '区域'
        } else if (val.length === 1 && !val.includes('0')) {
          this.addTitle = this.items[this.confirmActiveIndex].children[
            this.items[this.confirmActiveIndex].children.findIndex(item => {
              return item.id === val[0]
            })
          ].text
        } else {
          this.addTitle = '区域'
        }
      },
      deep: true
    }
  },
  methods: {
    // 加载列表
    async onLoad() {
      if (this.pageNum === 1) {
        this.loadingShow = true
      }
      try {
        const { data } = await getNetworkCompanyList({
          type: +this.$route.query.type,
          'page.current': this.pageNum,
          'page.size': this.pageSize,
          'sort.key': 0,
          'sort.value':
            this.sequence === '' || this.sequence === 'default'
              ? 'desc'
              : this.sequence,
          search: this.$route.query.text,
          district: this.confirmActiveIds.length
            ? this.confirmActiveIds[0] === '0'
              ? this.items[this.confirmActiveIndex].id
              : this.confirmActiveIds.toString()
            : null,
          status:
            this.status === 'all' || this.status === '' ? null : this.status,
          openDatetimeStart: this.confirmSelectTime
            ? this.confirmSelectTime.split('-')[0]
            : null,
          openDatetimeEnd: this.confirmSelectTime
            ? this.confirmSelectTime.split('-')[1]
            : null,
          cycle: this.confirmRadio ? +this.confirmRadio.split('-')[0] : null,
          cycleType: this.confirmRadio ? this.confirmRadio.split('-')[1] : null,
          nature: this.confirmCompanyPropertySelect.length
            ? this.confirmCompanyPropertySelect.toString()
            : null,
          scale: this.confirmCompanyScaleSelect.length
            ? this.confirmCompanyScaleSelect.toString()
            : null,
          internet: this.confirmCompanyInternetSelect.length
            ? this.confirmCompanyInternetSelect.toString()
            : null,
          isInternet:
            this.confirmIsInternet || this.confirmIsInternet === 0
              ? this.confirmIsInternet
              : null,
          emphasis:
            this.confirmEmphasis || this.confirmEmphasis === 0
              ? this.confirmEmphasis
              : null,
          highRisk:
            this.confirmHighRisk || this.confirmHighRisk === 0
              ? this.confirmHighRisk
              : null,
          payment:
            this.confirmPayment || this.confirmPayment === 0
              ? this.confirmPayment
              : null,
          drawingData:
            this.confirmDrawingsInformationSelect.length
              ? this.confirmDrawingsInformationSelect.toString()
              : null,
          fuzzy: +this.$route.query.type === 1 ? 0 : 1
        })
        this.total = data.data.total
        this.pageNum = this.pageNum + 1
        // 追加数据
        const results = data.data.records
        this.companyList.push(...results)
        this.loading = false
        if (!results.length) {
          this.finished = true
        }
        if (this.loadingShow) {
          this.loadingShow = false
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.loadingShow) {
          this.loadingShow = false
        }
        if (error.message.includes('timeout')) {
          if (this.$route.path === '/workTable/companyResult') {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
          }
        }
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 地区选择点击
    itemClickHandler(data) {
      if (data.id === '0') {
        this.activeIds = ['0']
      } else if (this.activeIds.includes('0')) {
        this.activeIds.splice(
          this.activeIds.findIndex(item => item === '0'),
          1
        )
      }
    },
    // 左侧点击
    navClick(index) {
      if (index !== this.items.length) {
        this.activeIds = ['0']
      }
    },
    // 区域选择确认
    confirmClick() {
      this.confirmActiveIndex = this.activeIndex
      this.confirmActiveIds = [...this.activeIds]
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.item.toggle(false)
    },
    // 打开区域下拉框
    addOpened() {
      this.activeIndex = this.confirmActiveIndex
      this.activeIds = [...this.confirmActiveIds]
      if (
        this.activeIndex === null ||
        !this.activeIds.length ||
        this.activeIndex === this.items.length
      ) {
        this.activeIndex = 0
        this.activeIds = ['0']
      }
    },
    // 打开状态下来
    statusOpened() {
      if (this.status === '') {
        this.status = 'all'
      }
    },
    // 打开时间选择
    showTimeSelect() {
      this.timeSelectShow = true
      this.flag = false
      if (this.selectTime) {
        this.$refs.timeSelectPicker.reset([
          new Date(this.selectTime.split('-')[0]),
          new Date(this.selectTime.split('-')[1])
        ])
      } else {
        this.$refs.timeSelectPicker.reset([new Date()])
      }
    },
    // 时间区间选择确认
    onConfirmTime(date) {
      const [start, end] = date
      this.timeSelectShow = false
      this.selectTime = `${dayjs(start).format('YYYY/MM/DD')}-${dayjs(
        end
      ).format('YYYY/MM/DD')}`
      this.radio = ''
    },
    // 开通时间标签改变
    openTimeChange(name) {
      if (name) {
        this.selectTime = ''
      }
    },
    // 打开开通日期下拉框
    openTimeOpened() {
      this.selectTime = this.confirmSelectTime
      this.radio = this.confirmRadio
    },
    // 开通日期确认
    confirmOpenTimeClick() {
      if (this.radio === '' && this.selectTime === '') {
        this.timeTitle = '开通日期'
      } else if (this.radio === '3-day' && this.selectTime === '') {
        this.timeTitle = '近三天'
      } else if (this.radio === '1-week' && this.selectTime === '') {
        this.timeTitle = '近一周'
      } else if (this.radio === '1-month' && this.selectTime === '') {
        this.timeTitle = '近一个月'
      } else if (this.radio === '3-month' && this.selectTime === '') {
        this.timeTitle = '近三个月'
      } else if (this.radio === '6-month' && this.selectTime === '') {
        this.timeTitle = '近半年'
      } else if (this.radio === '1-year' && this.selectTime === '') {
        this.timeTitle = '近一年'
      } else if (this.selectTime) {
        this.timeTitle = this.selectTime
      }
      this.confirmSelectTime = this.selectTime
      this.confirmRadio = this.radio
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.timeItem.toggle(false)
    },
    // 批量获取数据字典下拉列表
    async getDictionaryByTypeList() {
      const {
        data: { data }
      } = await getDictionaryByTypeList({
        types: [
          'company-property',
          'company-scale',
          'company-internet',
          'drawings-information'
        ].toString()
      })
      this.companyProperty = data['company-property']
      this.companyScale = data['company-scale']
      this.companyInternet = data['company-internet']
      this.drawingsInformation = data['drawings-information']
    },
    // 获取区域树形数据
    async getDistrictList() {
      const {
        data: { data }
      } = await getDistrictList()
      this.items = data.map(item => {
        item.children.unshift({
          text: '不限',
          id: '0'
        })
        return item
      })
    },
    // 打开更多下拉框
    openMoreOpened() {
      this.companyPropertySelect = this.confirmCompanyPropertySelect
      this.companyScaleSelect = this.confirmCompanyScaleSelect
      this.companyInternetSelect = this.confirmCompanyInternetSelect
      this.isInternet = this.confirmIsInternet
      this.emphasis = this.confirmEmphasis
      this.highRisk = this.confirmHighRisk
      this.payment = this.confirmPayment
      this.drawingsInformationSelect = this.confirmDrawingsInformationSelect
    },
    // 更多确认
    confirmMoreClick() {
      this.confirmCompanyPropertySelect = this.companyPropertySelect
      this.confirmCompanyScaleSelect = this.companyScaleSelect
      this.confirmCompanyInternetSelect = this.companyInternetSelect
      this.confirmIsInternet = this.isInternet
      this.confirmEmphasis = this.emphasis
      this.confirmHighRisk = this.highRisk
      this.confirmPayment = this.payment
      this.confirmDrawingsInformationSelect = this.drawingsInformationSelect
      if (
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (this.confirmCompanyPropertySelect.length === 1 &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          this.confirmCompanyScaleSelect.length === 1 &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          this.confirmCompanyInternetSelect.length === 1 &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet !== '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis !== '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk !== '' &&
          this.confirmPayment === '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment !== '' &&
          !this.confirmDrawingsInformationSelect.length) ||
        (!this.confirmCompanyPropertySelect.length &&
          !this.confirmCompanyScaleSelect.length &&
          !this.confirmCompanyInternetSelect.length &&
          this.confirmIsInternet === '' &&
          this.confirmEmphasis === '' &&
          this.confirmHighRisk === '' &&
          this.confirmPayment === '' &&
          this.confirmDrawingsInformationSelect.length === 1)
      ) {
        this.moreTitle = '更多'
      } else {
        this.moreTitle = '更多(多选)'
      }
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.moreItem.toggle(false)
    },
    // 状态变化
    statusChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    },
    // 打开排序
    sequenceOpened() {
      if (this.sequence === '') {
        this.sequence = 'default'
      }
    },
    // 排序变化
    sequenceChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .myLoading {
      top: 148px;
      bottom: 0;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .van-calendar__popup.van-popup--bottom,
    .van-calendar__popup.van-popup--top {
      height: 61% !important;
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__content {
        padding-left: 0;
      }

      .van-search__label {
        .van-dropdown-menu {
          height: 100%;
          width: 100%;
          background-color: transparent;
          overflow: hidden;

          .van-dropdown-menu__bar {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: transparent;
            box-shadow: none;

            .van-ellipsis {
              padding-right: 5px;
              font-size: 14px;
            }
          }

          .van-dropdown-menu__title::after {
            right: 1px;
          }
        }

        .van-dropdown-item {
          .van-dropdown-item__content {
            .van-dropdown-item__option {
              .van-cell__title {
                padding-left: 25px;
                font-size: 14px;
              }
            }
          }
        }

        .van-dropdown-menu__item--disabled {
          .van-dropdown-menu__title {
            color: #323233;
          }
        }
      }

      .siftDp {
        overflow: hidden;

        .van-dropdown-menu {
          >div {
            &:nth-child(5) {
              .van-dropdown-item__content {
                height: 100%;
                max-height: none;
                overflow: hidden;

                .more {
                  height: calc(100% - 52px);
                  overflow: auto;

                  .moreLabel {
                    font-size: 16px;
                    padding-left: 16px;
                  }

                  .itemValue {
                    .van-radio--horizontal {
                      margin-right: 12px;
                      width: auto;
                      margin-bottom: 0;
                    }
                  }
                }

                .myButton {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  padding-bottom: 15px;
                }
              }
            }
          }

          .van-dropdown-menu__bar {
            box-shadow: none;

            .van-dropdown-menu__item {
              &:nth-child(5) {
                flex: none;

                .noIcon {
                  &::after {
                    border: none;
                  }
                }

                .mySequence {
                  color: #3ea7f0;
                }
              }
            }
          }
        }

        .van-radio--horizontal {
          margin-right: 0;
          width: 25%;
          margin-bottom: 10px;
        }

        .van-checkbox--horizontal {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .myButton {
          padding: 5px 16px;
          display: flex;

          .van-button--small {
            margin: 0 5px;
          }
        }

        .van-sidebar-item--select::before {
          background-color: #3ea7f0;
        }

        .van-tree-select__item--active {
          color: #3ea7f0;
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px - 48px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 24px - 54px - 48px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
